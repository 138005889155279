import React from 'react';
// import CustomNavbar from '../components/common/CustomNavbar'; // Import the Navbar component
// import Footer from '../components/common/Footer';
import './healthcaredigitalmarketing.css'; // Import the CSS file for styling
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom

import pngwing from '../assets/img/pngwing.png';
import bg2 from '../assets/img/bg-2 1.png';
import IsolationMode from '../assets/img/Isolation_Mode.svg';
import IsolationMode3 from '../assets/img/Isolation_Mode3.svg';
import IsolationMode4 from '../assets/img/Isolation_Mode4.svg';
import IsolationMode2 from '../assets/img/Isolation_Mode2.svg';
import IsolationMode5 from '../assets/img/Isolation_Mode5.svg';
import Layer from '../assets/img/Layer.svg';
import department from '../assets/img/departments-5.png';

const HealthcareDigitalMarketing = () => {
    const navigate = useNavigate();

    const handleContainerClick = () => {
      // Handle the click event
      console.log('Icon container clicked');
      navigate('/healthcare-digital-marketing');
    };
  return (
    <>
      {/* <CustomNavbar /> */}
      <div className="HDM-content">
        <h1 className="HDM-nav-heading">Healthcare Digital <br/>Marketing</h1>
        <p className="HDM-paragraph1">Comprehensive Revenue Cycle Management (RCM) Services by RDS</p>
      </div>
      <div className="HDM-main-container1">
        <h1 className="HDM-heading2">
          <span className="HDM-heading-part1">What we can accomplish </span>
          <span className="HDM-heading-part2">together</span>
        </h1>
        <div className="HDM-grid-container">
          <div className="HDM-icon-container">
            <p className="HDM-text">Healthcare <br/>Search Engine <br/>Optimisation</p>
          </div>
          <div className="HDM-icon-container">
            <p className="HDM-text">Healthcare<br/> PPC <br/>management</p>
          </div>
          <div className="HDM-icon-container">
            <p className="HDM-text">Healthcare <br/>social media<br/> advertisement</p>
          </div>
          <div className="HDM-icon-container">
            <p className="HDM-text">Healthcare <br/> conversion rate <br/> optimise</p>
          </div>
          <div className="HDM-icon-container">
            <p className="HDM-text">Healthcare <br/>reputation <br/> management</p>
          </div>
        </div>
        <div className="HDM-gradient-container">
          <img src={pngwing} alt="Your Image" className="HDM-background-image" />
          <div className="HDM-container-wrapper">
            <h2 className="HDM-container-heading">Cutting-edge AI-powered healthcare <br/> digital marketing</h2>
            <div className="HDM-text-upper">
              <p>
                We focus on attracting more engaging leads and enhancing patient engagement<br/> through our digital
                healthcare marketing strategies. Our team of dedicated healthcare <br/> digital marketing experts
                specializes in building and optimizing SEO and PPC <br/> campaigns, significantly boosting your
                website's visibility on major search engines.
              </p>
            </div>
          </div>
        </div>

        <div className="HDM-grid-container1">
      <div className="HDM-icon-container1" onClick={handleContainerClick}>
        <img src={IsolationMode} alt="Icon 1" className="HDM-icon1" />
        <h3 className="HDM-heading">Social media marketing</h3>
        <p className="HDM-text1">
        Social media is one of the most effective ways to connect with more patients. We can assist you in promoting your 
        </p>
        <div className="learn-more-container">
    <div className="learn-more-text">Learn More</div>
   <div className="arrow">→</div>
  </div>
      </div>
      <div className="HDM-icon-container1">
        <img src={IsolationMode3} alt="Icon 2" className="HDM-icon1" />
        <h3 className="HDM-heading">Hipaa Management</h3>
        <p className="HDM-text1">
        Pay-per-click advertising is crucial for driving relevant traffic and generating specific leads for your website
        </p>
        <div className="learn-more-container">
    <div className="learn-more-text">Learn More</div>
   <div className="arrow">→</div>
  </div>
      </div>
      <div className="HDM-icon-container1">
        <img src={IsolationMode4} alt="Icon 3" className="HDM-icon1" />
        <h3 className="HDM-heading">Patient Experience Management</h3>
        <p className="HDM-text1">
        Search engine optimization (SEO) is crucial for elevating your website to the top of Google’s search rankings. 
        </p>
        <div className="learn-more-container">
    <div className="learn-more-text">Learn More</div>
   <div className="arrow">→</div>
  </div>
      </div>
      <div className="HDM-icon-container1">
        <img src={IsolationMode2} alt="Icon 4" className="HDM-icon1" />
        <h3 className="HDM-heading">Healthcare Digital Marketing</h3>
        <p className="HDM-text1">
        Website design is crucial for earning your audience's trust. Your site must look professional and clearly reflect the level 
        </p>
        <div className="learn-more-container">
    <div className="learn-more-text">Learn More</div>
   <div className="arrow">→</div>
  </div>
      </div>
      <div className="HDM-icon-container1">
        <img src={IsolationMode5} alt="Icon 5" className="HDM-icon1" />
        <h3 className="HDM-heading">Healthcare Analytics</h3>
        <p className="HDM-text1">
        By closely monitoring marketing trends and analyzing your site's performance, we can enhance
        </p>
        <div className="learn-more-container">
    <div className="learn-more-text">Learn More</div>
   <div className="arrow">→</div>
  </div>
      </div>
      <div className="HDM-icon-container1">
        <img src={Layer} alt="Icon 6" className="HDM-icon1" />
        <h3 className="HDM-heading">Electronic Health Record</h3>
        <p className="HDM-text1">
        A crucial aspect of enhancing your website is determining your company's focal points. By understanding what your patients
        </p>
        <div className="learn-more-container">
    <div className="learn-more-text">Learn More</div>
   <div className="arrow">→</div>
  </div>
      </div>
    </div>

        <div className="HM-image-container2">
          <img src={bg2} alt="description" className="HM-my-image1" />
          <div className="HM-overlay-content">
            <h1 className="HM-heading3">
              <span className="HM-heading-part11">We don't only promise <br /> results,</span>
              <span className="HM-heading-part22"> we prove them. </span>
            </h1>
            <p className="HM-paragraph2">
            A complete healthcare marketing solution <br />
At CareRCM®, we focus on crafting a patient-centered strategy tailored to your practice.<br /> 
Our team will evaluate your business needs within the current digital landscape and <br /> 
recommend the most effective digital marketing strategies to help your healthcare <br />
 business thrive
            </p>
            <img src={department} alt="Your Image" className="HM-dp-image" />
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default HealthcareDigitalMarketing ;
